import { Box, Center, HStack, Spinner, useToken } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines'

import { useColorModeValue } from '@app/components/ui/color-mode'
import useGetObjectProperty from '@app/hooks/useGetObjectProperty'
import useGetStrategyDateFilters from '@app/hooks/useGetStrategyDateFilters'
import { CardStat } from '@app/shared/cards/components'
import type { CardSize, DomainMetric } from '@app/types'
import { statLabel } from '@app/utils/metricHelpers'
import { loaderQuery } from '@graphql/client'
import { SampledMetricDataPoints } from '@graphql/documents/metric_data_point.graphql'
import { StatDisplayEnum } from '@graphql/types'

const MetricSparkline = ({ metric, strategyId, show = false }) => {
  const [sparklineLight, sparklineDark] = useToken('colors', ['blue.400', 'blue.500'])
  const sparklineColor = useColorModeValue(sparklineLight, sparklineDark)
  const [sampledMetricDataPoints, setSampledMetricDataPoints] = useState(null)
  const { id, updatedAt } = metric

  const fetchMetricDataPoints = useCallback(async () => {
    await loaderQuery(SampledMetricDataPoints, { metricId: id, strategyId }).then((res) => {
      setSampledMetricDataPoints(res?.data?.sampledMetricDataPoints)
    })
  }, [id, strategyId])

  useEffect(() => {
    fetchMetricDataPoints().then(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAt])

  if (!show) {
    return null
  }

  if (!sampledMetricDataPoints) {
    return (
      <Center display={show ? 'flex' : 'none'}>
        <Spinner size="xl" />
      </Center>
    )
  }

  const sparklineData = sampledMetricDataPoints?.map((datum) => datum.value) || []

  return (
    <Box display={show ? 'initial' : 'none'}>
      <Sparklines height={50} data={sparklineData}>
        <SparklinesLine color={sparklineColor} />
      </Sparklines>
    </Box>
  )
}

interface Props {
  strategyId: string
  metric: Pick<
    DomainMetric,
    | 'id'
    | 'displayFormat'
    | 'rollUp'
    | 'periodToDate'
    | 'strategyStats'
    | 'strategyNodeData'
    | 'positiveDirection'
    | 'compactDisplay'
    | 'maximumDisplayPrecision'
    | 'minimumDisplayPrecision'
    | 'redecimalPercentages'
  >
  size?: CardSize
}

const CardStats: FC<Props> = ({ strategyId, metric, size }) => {
  const {
    displayFormat,
    strategyStats: metricStats,
    strategyNodeData: { strategyStats = [] }
  } = metric

  const statDisplay: StatDisplayEnum = useGetObjectProperty(strategyId, 'strategy', 'statDisplay')

  const hasMetricStats = metricStats?.some((stat) => stat.total || stat.change)
  const hasStrategyStats = strategyStats.length > 0
  const strategyDateFilters = useGetStrategyDateFilters(strategyId)

  const baseStats = hasStrategyStats ? strategyStats : metricStats
  const includeAllStats = isEmpty(strategyDateFilters) || strategyDateFilters.range === 'trends'
  const stats = includeAllStats ? baseStats : baseStats.slice(0, 1)

  const showStats = hasMetricStats || hasStrategyStats

  if (!showStats) {
    return null
  }

  const hideChange = stats.length < 3
  const showCardStats = statDisplay === StatDisplayEnum.Stats && stats.some((stat) => stat.total || stat.change)

  return (
    <>
      <MetricSparkline
        metric={metric}
        strategyId={strategyId}
        show={statDisplay === StatDisplayEnum.Charts && showStats}
      />
      <HStack justify="space-between">
        {showCardStats
          ? stats.map((stat) => (
              <CardStat
                key={`${metric?.id}-${stat.period}`}
                period={statLabel(stat, metric.rollUp, metric.periodToDate, strategyDateFilters)}
                total={stat.total}
                change={stat.change}
                displayFormat={displayFormat}
                metric={metric}
                size={size}
                hideChange={hideChange}
              />
            ))
          : null}
      </HStack>
    </>
  )
}

export default CardStats
