import { Box, Text } from '@chakra-ui/react'
import { type ChangeEvent, useState } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import useGetObject from '@app/hooks/useGetObject'
import { useStore } from '@app/store'

const MapStatDisplay = ({ strategyId, canEdit = false }) => {
  const strategy = useGetObject(strategyId, 'strategy')
  const [value, setValue] = useState(strategy?.statDisplay || 'stats')
  const updateObject = useStore.use.updateObject()
  // const revalidator = useRevalidator()

  const onChange = (displayValue) => {
    const strategyParams = {
      strategy: { statDisplay: displayValue, id: strategyId }
    }

    updateObject(strategyParams).then(() => {})
  }

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(e.currentTarget.value)
    onChange(e.currentTarget.value)
  }

  if (!canEdit) {
    return null
  }

  return (
    <>
      <Box css={{ '--field-label-width': '40px' }}>
        <Field
          label={
            <Text fontSize="xs" color="var(--chakra-colors-color-palette-fg)">
              Display
            </Text>
          }
          orientation="horizontal"
        >
          <NativeSelectRoot size="xs" w="80px">
            <NativeSelectField value={value} onChange={handleChange}>
              <option value="stats">Stats</option>
              <option value="charts">Charts</option>
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
      </Box>
    </>
  )
}

export default MapStatDisplay
